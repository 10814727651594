module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Central Valley Engineering & Asphalt, Inc.","short_name":"Central Valley Engineering","start_url":"/","background_color":"#252525","theme_color":"#FFFF63","display":"standalone","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ece9b6248ebdeae808b4a21c257b8c89"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
